import selectableProductsModule from '@/store/campaigns/selectableProducts'
// eslint-disable-next-line no-unused-vars, object-curly-newline
import { computed, getCurrentInstance, readonly, ref, toRaw, watch } from '@vue/composition-api'

export default function useCampaignItemList() {
  const vm = getCurrentInstance().proxy
  const itemListTable = ref([])

  const MODULE_CONTAINER = 'campaigns'
  const SELECTABLE_PRODUCTS_MODULE = 'selectableProducts'
  const STORE_MODULE = computed(() => `${MODULE_CONTAINER}/${SELECTABLE_PRODUCTS_MODULE}`)

  const tableColumns = [
    { text: '#', value: 'number' },
    { text: 'TITLE', value: 'displayName' },
    { text: 'BRAND', value: 'brand' },
    { text: 'CATEGORY', value: 'itemCategoryCode' },
    { text: 'PRICE', value: 'unitPrice' },
  ]

  const items = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/items`],
  })
  const loading = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/loading`],
  })
  const meta = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/meta`],
  })
  const filters = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/filters`],
  })
  const options = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/options`],
    set: val => vm.$store.dispatch(`${STORE_MODULE.value}/setOptions`, { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/search`],
    set: val => vm.$store.dispatch(`${STORE_MODULE.value}/setSearch`, val),
  })
  const totalItemListTable = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/meta`].total,
  })

  const setOptions = val => vm.$store.dispatch(`${STORE_MODULE.value}/setOptions`, val)
  const setFilters = val => vm.$store.dispatch(`${STORE_MODULE.value}/setFilters`, val)
  const fetchItems = () => vm.$store.dispatch(`${STORE_MODULE.value}/fetchItems`)

  const init = () => {
    if (!vm.$store.hasModule([MODULE_CONTAINER, SELECTABLE_PRODUCTS_MODULE])) {
      vm.$store.registerModule([MODULE_CONTAINER, SELECTABLE_PRODUCTS_MODULE], selectableProductsModule)
    }
    fetchItems()
  }

  const destroy = () => {
    if (vm.$store.hasModule([MODULE_CONTAINER, SELECTABLE_PRODUCTS_MODULE])) {
      vm.$store.unregisterModule([MODULE_CONTAINER, SELECTABLE_PRODUCTS_MODULE])
    }
  }

  return {
    itemListTable,
    tableColumns,

    totalItemListTable,
    loading,

    fetchItems,

    items,
    meta,

    options,
    filters,
    search,

    setOptions,
    setFilters,

    init,
    destroy,
  }
}
