<template>
  <v-card
    v-if="!loading"
    class="user-edit-info pa-sm-10 pa-3"
  >
    <v-card-title class="justify-start text-h5">
      <span v-if="action === 'create'">
        {{ t('Create New Campaign') }}
      </span>
      <span v-if="action === 'update'">
        {{ t('Edit Campaign Information') }}
      </span>
    </v-card-title>

    <v-card-text class="mt-5">
      <v-form
        ref="campaignForm"
        class="multi-col-validation"
        @submit.prevent="onSubmit"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="data.campaignName"
              name="campaignName"
              :label="t('Campaign Name')"
              :rules="[validators.required]"
              :error-messages="errorMessages.campaignName"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
          >
            <!-- <v-text-field
              v-model="data.bannerImageUrl"
              name="bannerImageUrl"
              :label="t('Banner Image URL')"
              :rules="[validators.urlValidator]"
              :error-messages="errorMessages.bannerImageUrl"
            ></v-text-field> -->

            <!-- <v-file-input
              v-model="data.thumbUrl"
              truncate-length="100"
              name="image"
              :label="t('Image')"
              :error-messages="errorMessages.thumbUrl"
            ></v-file-input> -->

            <v-file-pond
              :controller.sync="pondController"
              name="image"
              disk="campaigns"
              max-files="1"
              class="w-100"
              :label="t('Image')"
              max-paralel-uploads="1"
              :allow-multiple="false"
              :errors="errorMessages.image"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="12"
          >
            <v-row align="end">
              <v-col
                align="start"
              >
                <label class="v-label">
                  <div class="mb-4">Products</div>
                </label>
              </v-col>
              <v-col align="end">
                <v-btn
                  x-small
                  color="primary"
                  class="mb-4 me-3"
                  @click="isDialogOpen = !isDialogOpen"
                >
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                  <span>Add New Product</span>
                </v-btn>
              </v-col>
            </v-row>

            <v-data-table
              v-sortable-data-table
              dense
              :headers="productTableColumns"
              :items="selectedItems"
              :item-class="renderRowClass"
              item-key="sort"
              :options="{
                sortBy: ['sort'],
                sortDesc: [false]
              }"
              disable-sort
              disable-pagination
              hide-default-footer
              @sorted="handleOrder"
            >
              <template #[`item.number`]="{item}">
                <router-link
                  :to="{ name : 'item-view', params : { id : item.id } }"
                  class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ item.number }}
                </router-link>
              </template>

              <template #[`item.displayName`]="{item}">
                <router-link
                  v-if="item.displayName"
                  :to="{ name : 'item-view', params : { id : item.id } }"
                  class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ item.displayName }}
                </router-link>
                <span v-else>-</span>
              </template>

              <template v-slot:item.slideImageUrl="props">
                <v-edit-dialog
                  :return-value.sync="props.item.slideImageUrl"
                  dense
                  large
                  persistent
                  @save="saveEdit"
                  @close="saveEdit"
                  @cancel="cancelEdit"
                >
                  <template v-slot:default>
                    <span v-if="props.item.slideImageUrl">
                      {{ props.item.slideImageUrl }}
                    </span>
                    <span
                      v-else
                      class="info--text"
                    >
                      {{ t('Click to edit Image URL') }}
                    </span>
                  </template>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.slideImageUrl"
                      :rules="[validators.urlValidator]"
                      :label="t('Edit Image URL')"
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template #[`item.actions`]="{item}">
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      :href="item.slideImageUrl"
                      :disabled="!item.slideImageUrl"
                      target="blank"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiEyeOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ t('View Image') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      color="error"
                      v-bind="attrs"
                      :disabled="!item || deleting"
                      :loading="isDialogOpen"
                      @click="removeCampaignItem(item.id)"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ t('Delete Video') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row v-if="isDirty">
            <v-col
              cols="12"
              class="d-flex justify-start mt-3"
            >
              <v-banner min-width="100%">
                <v-icon
                  slot="icon"
                  color="warning"
                  size="36"
                >
                  {{ icons.mdiUpdate }}
                </v-icon>

                <div class="d-flex flex-column">
                  <span>{{ t("Campaign information has changed!") }}</span>
                  <small>{{ t("Don't forget to submit the form.") }}</small>
                </div>
              </v-banner>
            </v-col>
          </v-row>
        </v-expand-transition>

        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-start mt-3"
          >
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :disabled="updating || pondController.uploading"
              :loading="updating || pondController.uploading"
            >
              {{ t('Submit') }}
            </v-btn>

            <v-btn
              v-if="handleDiscard"
              outlined
              color="secondary"
              class="me-3"
              :disabled="updating || pondController.uploading"
              :loading="updating || pondController.uploading"
              @click="handleDiscard"
            >
              {{ t('Discard') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <item-list-dialog
      :is-dialog-open.sync="isDialogOpen"
      :selected-items.sync="selectedItems"
    />
  </v-card>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import {
  ref,
  computed,
  onUnmounted,
  onMounted,
} from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline, no-unused-vars
import { mdiPlus, mdiUpdate, mdiEyeOutline, mdiDeleteOutline } from '@mdi/js'

// eslint-disable-next-line object-curly-newline, no-unused-vars
import { required, emailValidator, urlValidator, integerValidator } from '@core/utils/validation'

import ItemListDialog from './ItemListDialog.vue'
import useCampaignItemList from './useCampaignItemList'

export default {
  name: 'CampaignForm',
  components: {
    ItemListDialog,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({
        products: [],
      }),
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
    updating: {
      type: Boolean,
      default: false,
    },

    handleSubmit: {
      type: Function,
      required: true,
      default: () => {},
    },
    handleDiscard: {
      type: Function,
      default: null,
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const { t } = useUtils()

    const campaignForm = ref(null)
    const originalProducts = ref(Array.from(props.data?.products || []))
    const selectedItems = ref(Array.from(props.data?.products || []))

    const propIds = computed(() => Array.from(originalProducts.value.map(p => p.id)))
    const localIds = computed(() => Array.from(selectedItems.value.map(p => p.id)))

    const pondController = ref({
      files: [],
      uploading: false,
    })

    onMounted(() => {
      if (props.data.image && props.data.thumbUrl) {
        pondController.value.files = [{
          source: props.data.image,
          options: {
            type: 'local',
          },
        }]
      }
    })

    const isDirty = computed(() => {
      if (propIds.value.length !== localIds.value.length) return true

      if (propIds.value.some(id => !localIds.value.includes(id))) return true

      if (localIds.value.some(id => !propIds.value.includes(id))) return true

      if (localIds.value.toString() !== propIds.value.toString()) return true

      if (props.data.image !== (pondController.value.file?.filename || null)) return true

      return false
    })

    // eslint-disable-next-line object-curly-newline
    const { init, destroy } = useCampaignItemList()

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    const onSubmit = () => {
      // eslint-disable-next-line no-unreachable
      const isFormValid = campaignForm.value.validate()
      if (!isFormValid) return
      const formData = new FormData(campaignForm.value.$el)

      selectedItems.value.forEach((item, i) => {
        formData.append(`items[${i}][itemId]`, item.id)

        // formData.append(`items[${i}][sort]`, Number.parseInt(item.sort, 10) || Number.parseInt(i, 10) + 1)
        if (item.slideImageUrl) formData.append(`items[${i}][slideImageUrl]`, item.slideImageUrl)
      })

      props.handleSubmit(formData)

      // .then(res => res && emit('update:is-bio-dialog-open', false))
    }

    const productTableColumns = [
      { text: '#', value: 'number' },
      { text: 'TITLE', value: 'displayName' },
      { text: 'IMAGE URL', value: 'slideImageUrl' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const handleOrder = e => {
      // const movedItem = Array.from(selectedItems.value).splice(e.oldIndex, 1)[0]
      // const replacedItem = Array.from(selectedItems.value).splice(e.newIndex, 1)[0]
      // console.error({ movedItem, replacedItem })

      const reorderedItems = Array.from(selectedItems.value)
      reorderedItems.splice(e.newIndex, 0, reorderedItems.splice(e.oldIndex, 1)[0])

      selectedItems.value = reorderedItems
    }

    const renderRowClass = item => `product-${item.id}`

    const removeCampaignItem = itemId => {
      const itemIndex = selectedItems.value.findIndex(it => String(it.id) === String(itemId))
      if (itemIndex !== -1) selectedItems.value.splice(itemIndex, 1)
    }

    const isDialogOpen = ref(false)

    return {
      t,

      icons: {
        mdiPlus,
        mdiUpdate,
        mdiEyeOutline,
        mdiDeleteOutline,
      },

      validators: {
        required,
        urlValidator,
        emailValidator,
        integerValidator,
      },

      renderRowClass,
      productTableColumns,

      campaignForm,
      onSubmit,

      isDialogOpen,

      selectedItems,

      isDirty,

      handleOrder,
      removeCampaignItem,

      pondController,
    }
  },
}
</script>
