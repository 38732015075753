<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isDialogOpen"
    max-width="80%"
    @click:outside="$emit('update:is-dialog-open',false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Select Campaign Products
      </v-card-title>

      <v-card-text
        h-25
        class="h-25"
      >
        <!-- <v-row class="">
          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="options.q"
              placeholder="Search"
              hide-details
              dense
              class="user-search me-0 mb-4"
            >
            </v-text-field>
          </v-col>
        </v-row> -->

        <v-row class="">
          <v-col
            cols="12"
            sm="12"
          >
            <vuex-table
              v-model="localSelectedItems"
              show-select
              :headers="tableColumns"
              :items="items"
              :meta.sync="meta"
              :search.sync="search"
              :options="options"
              :loading="loading"
              search-container-class=""
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 20]
              }"
              @update:options="o => options = o"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="">
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-start mt-3"
          >
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :loading="updating"
              @click="onSubmit"
            >
              {{ t('Submit') }}
            </v-btn>

            <v-btn
              outlined
              color="secondary"
              :disabled="updating"
              @click="onDiscard"
            >
              {{ t('Discard') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref } from '@vue/composition-api'
import { mdiDeleteOutline } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import useCampaignItemList from './useCampaignItemList'

export default {
  name: 'ItemListDialog',

  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const { t } = useUtils()
    // eslint-disable-next-line object-curly-newline
    const { meta, items, filters, options, search, loading, tableColumns } = useCampaignItemList()

    const localSelectedItems = ref(props.selectedItems || [])

    const onSubmit = () => {
      emit(
        'update:selected-items',
        localSelectedItems.value.map((item, i) => ({
          ...item,
          sort: item.sort || i + 1,
        })),
      )
      emit('update:is-dialog-open', false)
    }
    const onDiscard = () => {
      emit('update:is-dialog-open', false)
    }

    return {
      t,

      onSubmit,
      onDiscard,

      localSelectedItems,

      icons: { mdiDeleteOutline },

      meta,
      items,
      search,
      filters,
      options,
      loading,
      tableColumns,
    }
  },
}
</script>
