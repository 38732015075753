import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"80%"},on:{"click:outside":function($event){return _vm.$emit('update:is-dialog-open',false)}},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c(VCard,{staticClass:"user-edit-info pa-sm-10 pa-3"},[_c(VCardTitle,{staticClass:"justify-center text-h5"},[_vm._v(" Select Campaign Products ")]),_c(VCardText,{staticClass:"h-25",attrs:{"h-25":""}},[_c(VRow,{},[_c(VCol,{attrs:{"cols":"12","sm":"12"}},[_c('vuex-table',{attrs:{"show-select":"","headers":_vm.tableColumns,"items":_vm.items,"meta":_vm.meta,"search":_vm.search,"options":_vm.options,"loading":_vm.loading,"search-container-class":"","footer-props":{
              'items-per-page-options': [5, 10, 15, 20]
            }},on:{"update:meta":function($event){_vm.meta=$event},"update:search":function($event){_vm.search=$event},"update:options":function (o) { return _vm.options = o; }},model:{value:(_vm.localSelectedItems),callback:function ($$v) {_vm.localSelectedItems=$$v},expression:"localSelectedItems"}})],1)],1)],1),_c(VCardActions,{},[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-start mt-3",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary","type":"submit","loading":_vm.updating},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.t('Submit'))+" ")]),_c(VBtn,{attrs:{"outlined":"","color":"secondary","disabled":_vm.updating},on:{"click":_vm.onDiscard}},[_vm._v(" "+_vm._s(_vm.t('Discard'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }